/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from "vue";
import VueRx from "vue-rx";
import CompleApp from "../components/compl.vue";
import LanguageApp from "../components/language.vue";
import CardApp from "../components/postcard.vue";
import CalendarApp from "../components/calendar.vue";
import BirthdayApp from "../components/birthday.vue";
import NewYearApp from "../components/newyear.vue";
import DoctorDayApp from "../components/doctorday.vue";
import MotherDayApp from "../components/motherday.vue";
import February23App from "../components/23february.vue";
import March8App from "../components/8march.vue";
import Event from "../components/event.vue";
import UrdApp from "../components/urd_calendar.vue"
import VampApp from "../components/vamp_calendar.vue"
import GastrozabotaApp from "../components/gastrozabota_calendar.vue"
import VueViewer from "v-viewer";
import vmodal from "vue-js-modal";
import VueYandexMetrika from "vue-yandex-metrika";

Vue.use(VueRx);
Vue.use(VueViewer);
Vue.use(vmodal);

Vue.use(VueYandexMetrika, {
  id: 86820461,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: (h) => h(CompleApp),
  }).$mount();
  if (document.getElementById("compl") != null) {
    document.getElementById("compl").appendChild(app.$el);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: (h) => h(CalendarApp),
  }).$mount();
  if (document.getElementById("calendar") != null) {
    document.getElementById("calendar").appendChild(app.$el);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: (h) => h(LanguageApp),
  }).$mount();
  if (document.getElementById("language") != null) {
    document.getElementById("language").appendChild(app.$el);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: (h) => h(CardApp),
  }).$mount();
  if (document.getElementById("postcard") != null) {
    document.getElementById("postcard").appendChild(app.$el);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: (h) => h(BirthdayApp),
  }).$mount();
  if (document.getElementById("birthday") != null) {
    document.getElementById("birthday").appendChild(app.$el);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: (h) => h(NewYearApp),
  }).$mount();
  if (document.getElementById("newyear") != null) {
    document.getElementById("newyear").appendChild(app.$el);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: (h) => h(DoctorDayApp),
  }).$mount();
  if (document.getElementById("doctorday") != null) {
    document.getElementById("doctorday").appendChild(app.$el);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: (h) => h(MotherDayApp),
  }).$mount();
  if (document.getElementById("motherday") != null) {
    document.getElementById("motherday").appendChild(app.$el);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: (h) => h(February23App),
  }).$mount();
  if (document.getElementById("23february") != null) {
    document.getElementById("23february").appendChild(app.$el);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: (h) => h(March8App),
  }).$mount();
  if (document.getElementById("8march") != null) {
    document.getElementById("8march").appendChild(app.$el);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: (h) => h(Event),
  }).$mount();
  if (document.getElementById("event") != null) {
    document.getElementById("event").appendChild(app.$el);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: (h) => h(UrdApp),
  }).$mount();
  if (document.getElementById("urd-calendar") != null) {
    document.getElementById("urd-calendar").appendChild(app.$el);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: (h) => h(VampApp),
  }).$mount();
  if (document.getElementById("vamp-calendar") != null) {
    document.getElementById("vamp-calendar").appendChild(app.$el);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: (h) => h(GastrozabotaApp),
  }).$mount();
  if (document.getElementById("gastrozabota-calendar") != null) {
    document.getElementById("gastrozabota-calendar").appendChild(app.$el);
  }
});

// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>

// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
