export const localPostCard = {
  ru: {
    postcard: {
      title: "Выбери, к чему будет приурочена открытка",
      new_year: "Новый год",
      mother_day: "День матери",
      birthday: "День рождения",
      doctor_day: "День медика",
      march: "8 марта",
      february: "23 февраля",
      event: "Приглашение Биннофарм Групп",
      back: "Назад",
    },
  },
  am: {
    postcard: {
      title: "Ընտրեք, թե ինչ առիթով է բացիկը",
      new_year: "Նոր տարի",
      mother_day: "Մայրերի օր",
      birthday: "Ծննդյան օր",
      doctor_day: "Բժշկի օր",
      march: "Մարտի 8",
      february: "Փետրվարի 23",
      event: "Հրավեր Բիննոֆարմ Խումբ",
      back: "Հետ",
    },
  },
  uz: {
    postcard: {
      title: "Откирткани қайси воқеага бағишлашни танланг",
      new_year: "Янги йил",
      mother_day: "Оналар куни",
      birthday: "Туғилган кун",
      doctor_day: "Шифокорлар куни",
      march: "8 март",
      february: "23 февраль",
      event: "Таклифнома Binnopharm Group",
      back: "Орқага",
    },
  },
  kz: {
    postcard: {
      title: "Открытканы қандай оқиғаға арнайтыныңызды таңдаңыз",
      new_year: "Жаңа жыл",
      mother_day: "Аналар күні",
      birthday: "Туған күн",
      doctor_day: "Дәрігер күні",
      march: "8 наурыз",
      february: "23 ақпан",
      event: "Шақыру Биннофарм Тобы",
      back: "Артқа",
    },
  },
  az: {
    postcard: {
      title: "Açıqçanı hansı hadisəyə həsr edəcəyinizi seçin",
      new_year: "Yeni il",
      mother_day: "Analar günü",
      birthday: "Ad günü",
      doctor_day: "Həkimlər günü",
      march: "8 mart",
      february: "23 fevral",
      event: "Dəvətnamə Binnopharm Qrupu",
      back: "Geri",
    },
  },
  md: {
    postcard: {
      title: "Alegeți pentru ce ocazie va fi cartea poștală",
      new_year: "Anul Nou",
      mother_day: "Ziua Mamei",
      birthday: "Ziua de naștere",
      doctor_day: "Ziua Medicilor",
      march: "8 martie",
      february: "23 februarie",
      event: "Invitație Grupul Binnopharm",
      back: "Înapoi",
    },
  },
  ge: {
    postcard: {
      title: "აირჩიეთ, რომელი შემთხვევისთვის იქნება ბარათი",
      new_year: "ახალი წელი",
      mother_day: "დედის დღე",
      birthday: "დაბადების დღე",
      doctor_day: "ექიმების დღე",
      march: "8 მარტი",
      february: "23 თებერვალი",
      event: "მოწვევა ბინოფარმ ჯგუფი",
      back: "უკან",
    },
  },
  ro: {
    postcard: {
      title: "Alege pentru ce va fi dedicată felicitarea",
      new_year: "Anul Nou",
      mother_day: "Ziua Mamei",
      birthday: "Ziua de naștere",
      doctor_day: "Ziua Medicului",
      march: "8 martie",
      february: "23 februarie",
      event: "Invitație Binnofarm Group",
      back: "Înapoi",
    },
  },
};