export const localCards = {
  ru: { // Russian
    step1_title: "Сделай коллегам комплимент",
    step1_new_year: "Открытка к Новому году",
    step1_birthday: "Открытка ко дню Рождения",
    step1_mother_day: "Открытка ко дню матери",
    step1_doctor_day: "Открытка ко дню медика",
    step1_8march: "Открытка к 8 марта",
    step1_23february: "Открытка к 23 февраля",
    step1_placeholder: "Введите имя того, кого хотите поздравить",
    steps_title: "Генератор\u00A0открытки",
    step2_name: "Выбрать другое имя",
    step3_title: "Ваша открытка успешно создана!",
    print_instruction: "Инструкция для печати",
    events: {
      title: "Пригласи коллегу на мероприятие",
      subtitle: "Открытка-приглашение на научно-образовательное мероприятие",
      name_placeholder: "Введите имя и отчество гостя",
      theme_placeholder: "Введите тему мероприятия",
      place_placeholder: "Введите место мероприятия",
      date_placeholder: "Введите время мероприятия",
      manager_placeholder: "Введите контакты менеджера",
      steps_title: "Генератор\u00A0открыток-приглашений",
      step2_btn: "Изменить текст",
      step3_btn: "Изменить имя",
      step3_title: "Ваша приглашение успешно создана!",
      share: "Дорогой коллега, приглашаем вас на наше мероприятие!",
    },
    download: "Скачать",
    share: "Поделиться с коллегами",
    share_new_year: "От души поздравляем с Новым годом! Пусть он принесет много радостных моментов и новых возможностей, а в доме всегда царит тепло и уют!\n\nСоздайте свой поздравительный календарь по ссылке https://bnphrmgroup.ru/cld. Сделайте приятно своим близким!",
    share_main: "Коллега, от души поздравляем вас с праздником! Создайте свою поздравительную открытку по ссылке https://bnphrmgroup.ru/comple. Сделайте приятно своим близким!",
    repeat: "Сделать ещё",
    hint: "Для сохранения высокого качества изображения рекомендуется делиться через электронную почту либо скачать изображение и отправить его без сжатия любым удобным способом.",
    ready: "Готово",
    ready_disabled: "Создание...",
    back: "Назад",
    instruction: {
      title: "Инструкция",
      subtitle: "Данный генератор позволяет создать именную открытку.",
      p1: `Введите имя в поле`,
      p2: `Можете ввести другое имя, нажав на кнопку`,
      p3: `Для завершения нажмите кнопку`,
      p4: "Через некоторое время\u00A0Вы увидите результат, который можно увеличить, нажав на него.",
      p5: "Вы\u00A0можете скачать результат, нажав на\u00A0кнопку",
      p6: "Также Вы\u00A0можете поделиться результатом с\u00A0коллегами и\u00A0подарить им\u00A0праздничное настроение уже сейчас, нажав на\u00A0кнопку",
      p7: "Если хотите пересоздать открытку или создать новую, нажмите на\u00A0ссылку",
      p_hooks: "(Доступно для устройств, поддерживающих такую возможность)",
      footer: "Порадуйте себя и своих коллег!",
      btn: "Понятно, приступим!",
    },
    print: {
      title: "Инструкция для печати",
      desktop: "ПК:",
      mobile: "Мобильные устройства:",
      p1: `Сохраните открытку себе на\u00A0устройство. Откройте изображение в\u00A0удобном для вас редакторе, отправьте на\u00A0печать. Либо выберите пункт &laquo;Печать&raquo; в\u00A0меню файла.`,
      p2: `Чтобы сохранить качество изображения, сгенерируйте открытку в\u00A0сервисе, затем сохраните его на\u00A0своем устройстве и\u00A0отправьте на\u00A0печать.`,
      p3: `Также открыткой можно поделиться через мессенджеры. Затем сохранить изображение с\u00A0мессенджера на\u00A0свое устройство и\u00A0отправить на\u00A0печать. При этом качество изображения будет хуже. При печати таким методом качество изображения теряется.`,
    },
    modal: {
      footer: "Порадуйте себя и своих коллег!",
      btn: "Понятно, приступим!",
    },
  },
  am: {  // Armenian
    step1_title: "Կատարիր գործընկերներին հաճոյախոսություն",
    step1_new_year: "Բացիկ Նոր տարվա առիթով",
    step1_birthday: "Բացիկ ծննդյան օրվա առիթով",
    step1_mother_day: "Բացիկ Մայրերի օրվա առիթով",
    step1_doctor_day: "Բացիկ Բժշկի օրվա առիթով",
    step1_8march: "Բացիկ Մարտի 8-ի առիթով",
    step1_23february: "Բացիկ Փետրվարի 23-ի առիթով",
    step1_placeholder: "Մուտքագրեք անունը",
    steps_title: "Բացիկների գեներատոր",
    step2_name: "Ընտրել այլ անուն",
    step3_title: "Ձեր բացիկը հաջողությամբ ստեղծված է!",
    print_instruction: "Տպման ուղեցույց",
    events: {
      title: "Հրավիրեք գործընկերոջը միջոցառման",
      subtitle: "Հրավեր-բացիկ գիտա-կրթական միջոցառման համար",
      name_placeholder: "Մուտքագրեք անունը",
      theme_placeholder: "Մուտքագրեք թեման",
      place_placeholder: "Մուտքագրեք վայրը",
      date_placeholder: "Մուտքագրեք ժամանակը",
      manager_placeholder: "Մուտքագրեք մենեջերի կոնտակտները",
      steps_title: "Հրավեր-բացիկների գեներատոր",
      step2_btn: "Փոփոխել տեքստը",
      step3_btn: "Փոփոխել անունը",
      step3_title: "Ձեր հրավերը հաջողությամբ ստեղծված է!",
      share: "Հարգելի գործընկեր, հրավիրում ենք Ձեզ մեր միջոցառմանը:",
    },
    download: "Ներբեռնել",
    share: "Կիսվել գործընկերների հետ",
    repeat: "Կատարել կրկին",
    hint: "Բարձր որակի պատկերը պահպանելու համար խորհուրդ է տրվում կիսվել էլեկտրոնային փոստով կամ ներբեռնել պատկերը և ուղարկել առանց սեղմման:",
    ready: "Պատրաստ է",
    ready_disabled: "Ստեղծվում է...",
    back: "Հետ",
    instruction: {
      title: "Ուղեցույց",
      subtitle: "Այս գեներատորը թույլ է տալիս ստեղծել անվանական բացիկ:",
      p1: "Մուտքագրեք անունը դաշտում",
      p2: "Կարող եք մուտքագրել այլ անուն՝ սեղմելով կոճակին",
      p3: "Ավարտելու համար սեղմեք կոճակը",
      p4: "Որոշ ժամանակ անց Դուք կտեսնեք արդյունքը, որը կարող եք մեծացնել՝ սեղմելով նրա վրա:",
      p5: "Դուք կարող եք ներբեռնել արդյունքը՝ սեղմելով կոճակը",
      p6: "Կարող եք նաև կիսվել արդյունքով գործընկերների հետ՝ սեղմելով կոճակը",
      p7: "Եթե ցանկանում եք վերաստեղծել կամ ստեղծել նոր բացիկ, սեղմեք հղմանը",
      p_hooks: "(Հասանելի է այն սարքերի համար, որոնք ապահովում են նման հնարավորություն)",
      footer: "Ուրախացրեք Ձեզ և Ձեր գործընկերներին!",
      btn: "Հասկանալի է, սկսենք!",
    },
    print: {
      title: "Տպման ուղեցույց",
      desktop: "Համակարգիչ՝",
      mobile: "Բջջային սարքեր՝",
      p1: "Պահպանեք բացիկը Ձեր սարքում: Բացեք պատկերը հարմար խմբագրում և ուղարկեք տպելու կամ ընտրեք «Տպել» կետը ֆայլի մենյուից:",
      p2: "Որակը պահպանելու համար սերվիսի միջոցով ստեղծեք բացիկ, այնուհետև պահպանեք սարքում և ուղարկեք տպելու:",
      p3: "Բացիկը կարելի է նաև կիսվել մեսենջերների միջոցով, այնուհետև պահել սարքում և ուղարկել տպելու: Այս մեթոդի դեպքում որակը վատթարանում է:",
    },
    modal: {
      footer: "Ուրախացրեք Ձեզ և Ձեր գործընկերներին!",
      btn: "Հասկանալի է, սկսենք!",
    },
  },
  az: {  // Azerbaijani
    step1_title: "Həmkarlarına kompliment et",
    step1_new_year: "Yeni il üçün açıqça",
    step1_birthday: "Ad günü üçün açıqça",
    step1_mother_day: "Ana günü üçün açıqça",
    step1_doctor_day: "Tibb işçisi günü üçün açıqça",
    step1_8march: "8 mart üçün açıqça",
    step1_23february: "23 fevral üçün açıqça",
    step1_placeholder: "Adı daxil edin", 
    steps_title: "Açıqça Generatoru",
    step2_name: "Başqa bir ad seç",
    step3_title: "Açıqçanız uğurla yaradıldı!",
    print_instruction: "Çap təlimatı",
    events: {
      title: "Həmkarını tədbirə dəvət et",
      subtitle: "Elmi-tədris tədbirinə dəvət açıqçası",
      name_placeholder: "Adı daxil edin", 
      theme_placeholder: "Mövzunu daxil edin",
      place_placeholder: "Məkanı daxil edin", 
      date_placeholder: "Vaxtı daxil edin", 
      manager_placeholder: "Menecerin əlaqələrini daxil edin",
      steps_title: "Dəvət açıqçaları generatoru",
      step2_btn: "Mətni dəyiş",
      step3_btn: "Adı dəyiş",
      stes3_title: "Dəvətiniz uğurla yaradıldı!",
      share: "Hörmətli həmkar, sizi tədbirimizə dəvət edirik!",
    },
    download: "Yüklə",
    share: "Həmkarlarla paylaş",
    share_new_year: "Yeni il münasibətilə ürəkdən təbrik edirik! İliniz sevinc dolu anlarla və yeni imkanlarla dolu olsun, evinizdə həmişə istilik və rahatlıq hökm sürsün!\n\nTəbrik təqviminizi bu linkdən yaradın: https://bnphrmgroup.ru/cld. Yaxınlarınızı sevindirin!",
    share_main: "Hörmətli həmkar, sizi ürəkdən təbrik edirik! Öz təbrik açıqçanızı bu linkdən yaradın: https://bnphrmgroup.ru/comple. Yaxınlarınızı sevindirin!",
    repeat: "Yenidən et",
    hint: "Yüksək keyfiyyətli görüntünü saxlamaq üçün elektron poçt vasitəsilə paylaşmağı və ya şəkli yükləyib sıxılmadan göndərməyi tövsiyə edirik.",
    ready: "Hazırdır",
    ready_disabled: "Yaradılır...",
    back: "Geri",
    instruction: {
      title: "Təlimat",
      subtitle: "Bu generator fərdi açıqça yaratmağa imkan verir.",
      p1: "Adı müvafiq sahəyə daxil edin",
      p2: "Başqa bir ad daxil etmək üçün düyməni basın",
      p3: "Tamamlamaq üçün düyməni basın",
      p4: "Bir müddət sonra nəticəni görəcəksiniz və üzərinə basaraq böyüdə bilərsiniz.",
      p5: "Nəticəni saxlamaq üçün düyməni basın",
      p6: "Nəticəni həmkarlarınızla paylaşaraq bayram əhval-ruhiyyəsi yaradın, düyməni basın",
      p7: "Yeni açıqça yaratmaq və ya yenidən yaratmaq üçün linkə basın",
      p_hooks: "(Bu imkanları dəstəkləyən cihazlar üçün mövcuddur)",
      footer: "Özünüzü və həmkarlarınızı sevindirin!",
      btn: "Anladım, başlayaq!",
    },
    print: {
      title: "Çap təlimatı",
      desktop: "PC:",
      mobile: "Mobil cihazlar:",
      p1: "Şəkli cihazınıza yükləyin. Görüntünü uyğun redaktorda açın və çap edin. Yaxud fayl menyusunda &laquo;Çap&raquo; seçin.",
      p2: "Görüntü keyfiyyətini qorumaq üçün açıqçanı xidmət vasitəsilə yaradın, cihazınıza yükləyin və çap edin.",
      p3: "Açıqça ilə messencer vasitəsilə paylaşa bilərsiniz. Messencerdən görüntünü yükləyib çap edin. Bu üsulla keyfiyyət aşağı düşə bilər.",
    },
    modal: {
      footer: "Özünüzü və həmkarlarınızı sevindirin!",
      btn: "Anladım, başlayaq!",
    },
  },
  uz: {  // Uzbekistan
    step1_title: "Хамкасбларингизга илтифот",
    step1_new_year: "Янги йил табрикномаси",
    step1_birthday: "Тугилган кун табрикномаси",
    step1_mother_day: "Оналар куни табрикномаси",
    step1_doctor_day: "Тиббиёт ходимлари куни табрикномаси",
    step1_8march: "8-март табрикномаси",
    step1_23february: "23-февраль табрикномаси",
    step1_placeholder: "Исмни киритинг",
    steps_title: "Табрикнома генератори",
    step2_name: "Бошқа исмни танланг",
    step3_title: "Табрикномангиз муваффақиятли яратилди!",
    print_instruction: "Босиб чиқариш бўйича кўрсатма",
    events: {
      title: "Ҳамкасбингизни тадбирга таклиф қилинг",
      subtitle: "Илмий-маърифий тадбирга таклифнома",
      name_placeholder: "Исмни киритинг",
      theme_placeholder: "Мавзуни киритинг",
      place_placeholder: "Жойни киритинг",
      date_placeholder: "Вақтни киритинг",
      manager_placeholder: "Менежернинг алоқа киритинг",
      steps_title: "Таклифнома генератори",
      step2_btn: "Матнни ўзгартириш",
      step3_btn: "Исмни ўзгартириш",
      stes3_title: "Таклифномангиз муваффақиятли яратилди!",
      share: "Ҳурматли ҳамкасб, сизни бизнинг тадбиримизга таклиф қиламиз!",
    },
    download: "Юклаб олиш",
    share: "Ҳамкасбларингиз билан ўртоқлашинг",
    share_new_year: "Янги йил муборак бўлсин! Янги йил сизга кўплаб қувончли лаҳзалар ва янги имкониятлар олиб келсин, уйингизда ҳар доим ислик ва қулайлик бўлсин!\n\nТабрикномангизни https://bnphrmgroup.ru/cld хаволаси орқали яратинг. Яқинларнингизни қувонтиринг!",
    share_main: "Ҳурматли ҳамкасб, сизни байрам билан чин юракдан табриклаймиз! Ўзингизнинг табрикномангизни https://bnphrmgroup.ru/comple хаволаси орқали яратинг. Яқинларнингизни хурсанд қилинг!",
    repeat: "Яна яратиш",
    hint: "Тасвир сифатини сақлаб қолиш учун уни электрон почта орқали ўртоқлашишни ёки расмни юклаб олиб, қимматсизидан ҳар қандай қулай усулда юбришни тавсия этамиз.",
    ready: "Тайёр",
    ready_disabled: "Яратилмоқда...",
    back: "Орқага",
    instruction: {
      title: "Кўрсатма",
      subtitle: "Ушбу генератор номли табрикнома яратишга ёрдам беради.",
      p1: "Майдонга исмни киритинг",
      p2: "Бошқа исмни киритиш учун тугмани босинг",
      p3: "Якунлаш учун тугмани босинг",
      p4: "Бир оз вақт ўтгач натижани кўрасиз, уни катталаштириш учун унга босинг.",
      p5: "Натижани юклаб олиш учун тугмани босинг",
      p6: "Шунингдек, натижани ҳамкасбларингиз билан ўртоқлашишингиз ва уларга ҳозирданоқ байрам кайфиятини туҳфа қилишингиз мумкин, бунинг учун тугмани босинг",
      p7: "Агар табрикномани қайта яратмоқчи ёки янги яратмоқчи бўлсангиз, хаволани босинг",
      p_hooks: "(Бу имкониятни қўллаб-қувватлайдиган қурилмаларда мавжуд)",
      footer: "Ўзингизни ва ҳамкасбларингизни хурсанд қиланг!",
      btn: "Тушунарли, бошлаймиз!",
    },
    print: {
      title: "Босиб чиқариш бўйича кўрсатма",
      desktop: "Компьютер:",
      mobile: "Мобил қурилмалар:",
      p1: "Табрикномани қурилмангизга юклаб олинг. Расмларни сиз учун қулай бўлган таҳрирловчида очинг ва босиб чиқаришга юборинг. Ёки файл менюсидан \"Чоп этиш\" бандини танланг.",
      p2: "Тасвир сифатини сақлаб қолиш учун табрикномани хизматда яратинг, сўнг уни қурилмангизга юклаб олинг ва босиб чиқаришга юборинг.",
      p3: "Шунингдек, табрикномани мессенжерлар орқали ўртоқлашинг. Кейин мессенжердан тасвирни қурилмангизга юклаб олиб, босиб чиқаришга юборинг. Ушбу усул билан тасвир сифатининг ёмонлашиши мумкин.",
    },
    modal: {
      footer: "Ўзингизни ва ҳамкасбларингизни хурсанд қиланг!",
      btn: "Тушунарли, бошлаймиз!",
    },
  },
  md: {  // Moldovan
    step1_title: "Fă un compliment colegilor",
    step1_new_year: "Carte poștală de Anul Nou",
    step1_birthday: "Carte poștală de ziua de naștere",
    step1_mother_day: "Carte poștală de Ziua Mamei",
    step1_doctor_day: "Carte poștală de Ziua Medicului",
    step1_8march: "Carte poștală de 8 Martie",
    step1_23february: "Carte poștală de 23 Februarie",
    step1_placeholder: "Introduceți numele", 
    steps_title: "Generator de\u00A0cărți poștale",
    step2_name: "Alegeți un alt nume",
    step3_title: "Felicitarea a fost creată cu succes!",
    print_instruction: "Instrucțiuni pentru imprimare",
    events: {
      title: "Invită un coleg la un eveniment",
      subtitle: "Carte poștală-invitație la un eveniment educațional-științific",
      name_placeholder: "Introduceți numele", 
      theme_placeholder: "Introduceți tema",
      place_placeholder: "Introduceți locația", 
      date_placeholder: "Introduceți ora", 
      manager_placeholder: "Introduceți contactele managerului",
      steps_title: "Generator de\u00A0cărți poștale de invitație",
      step2_btn: "Modificați textul",
      step3_btn: "Modificați numele",
      stes3_title: "Invitația a fost creată cu succes!",
      share: "Stimate coleg, vă invităm la evenimentul nostru!",
    },
    download: "Descărcați",
    share: "Distribuiți colegilor",
    share_new_year: "Vă felicităm din inimă cu Anul Nou! Fie ca acesta să aducă multe momente fericite și oportunități noi, iar în casă să domnească mereu căldura și confortul!\n\nCreați-vă propriul calendar festiv accesând linkul https://bnphrmgroup.ru/cld. Bucurați-vă apropiații!",
    share_main: "Stimate coleg, vă felicităm din inimă cu ocazia sărbătorii! Creați-vă propria carte poștală festivă accesând linkul https://bnphrmgroup.ru/comple. Bucurați-vă apropiații!",
    repeat: "Creați încă o dată",
    hint: "Pentru a păstra calitatea înaltă a imaginii, este recomandat să distribuiți prin email sau să descărcați imaginea și să o trimiteți fără compresie prin orice metodă convenabilă.",
    ready: "Gata",
    ready_disabled: "Creare...",
    back: "Înapoi",
    instruction: {
      title: "Instrucțiuni",
      subtitle: "Acest generator permite crearea unei cărți poștale personalizate.",
      p1: "Introduceți numele în câmpul indicat",
      p2: "Puteți introduce un alt nume apăsând butonul",
      p3: "Pentru a finaliza, apăsați butonul",
      p4: "După un timp\u00A0scurt, veți vedea rezultatul pe care îl puteți mări apăsând pe el.",
      p5: "Puteți descărca rezultatul apăsând pe butonul",
      p6: "De asemenea, puteți distribui rezultatul colegilor și\u00A0le oferiți o stare festivă deja acum, apăsând pe butonul",
      p7: "Dacă doriți să recreați cartea poștală sau să creați una nouă, apăsați pe linkul",
      p_hooks: "(Disponibil pentru dispozitive care suportă această funcționalitate)",
      footer: "Bucurați-vă pe voi și colegii voștri!",
      btn: "De acord, să începem!",
    },
    print: {
      title: "Instrucțiuni pentru imprimare",
      desktop: "PC:",
      mobile: "Dispozitive mobile:",
      p1: "Salvați cartea poștală pe\u00A0dispozitivul dumneavoastră. Deschideți imaginea în\u00A0editorul preferat și\u00A0trimiteți-o la\u00A0imprimare. Sau alegeți opțiunea &laquo;Imprimare&raquo; din\u00A0meniul fișierului.",
      p2: "Pentru a păstra calitatea imaginii, generați cartea poștală în\u00A0serviciu, apoi salvați-o pe\u00A0dispozitivul dumneavoastră și\u00A0trimiteți-o la\u00A0imprimare.",
      p3: "De asemenea, cartea poștală poate fi distribuită prin mesagerie. Salvați imaginea din\u00A0mesagerie pe\u00A0dispozitivul dumneavoastră și\u00A0trimiteți-o la\u00A0imprimare. Cu această metodă, calitatea imaginii poate scădea.",
    },
    modal: {
      footer: "Bucurați-vă pe voi și colegii voștri!",
      btn: "De acord, să începem!",
    },
  },
  ge: {  // Georgian
    step1_title: "გაუკეთე კოლეგებს კომპლიმენტი",
    step1_new_year: "გილოცავთ ახალ წელს",
    step1_birthday: "გილოცავთ დაბადების დღეს",
    step1_mother_day: "გილოცავთ დედის დღეს",
    step1_doctor_day: "გილოცავთ მედიკოსის დღეს",
    step1_8march: "გილოცავთ 8 მარტს",
    step1_23february: "გილოცავთ 23 თებერვალს",
    step1_placeholder: "შეიყვანეთ სახელი",
    steps_title: "ბარათის გენერატორი",
    step2_name: "შეარჩიეთ სხვა სახელი",
    step3_title: "თქვენი ბარათი წარმატებით შეიქმნა!",
    print_instruction: "ბეჭდვის ინსტრუქცია",
    events: {
      title: "მოიწვიე კოლეგა ღონისძიებაზე",
      subtitle: "ღონისძიებაზე მოსაწვევი ბარათი",
      name_placeholder: "შეიყვანეთ სახელი",
      theme_placeholder: "შეიყვანეთ თემა",
      place_placeholder: "შეიყვანეთ ადგილი",
      date_placeholder: "შეიყვანეთ დრო",
      manager_placeholder: "შეიყვანეთ მენეჯერის კონტაქტი",
      steps_title: "მოსაწვევი ბარათის გენერატორი",
      step2_btn: "ტექსტის შეცვლა",
      step3_btn: "სახელის შეცვლა",
      stes3_title: "თქვენი მოსაწვევი წარმატებით შეიქმნა!",
      share: "ძვირფასო კოლეგავ, გიწვევთ ჩვენს ღონისძიებაზე!",
    },
    download: "გადმოწერა",
    share: "გაზიარება კოლეგებთან",
    share_new_year: "გილოცავთ ახალ წელს! გისურვებთ უამრავ სასიხარულო მომენტსა და ახალ შესაძლებლობებს, ხოლო სახლში მუდამ სითბო და კომფორტი სუფევდეს!\n\nშექმენით თქვენი მისალოცი ბარათი ლინკზე https://bnphrmgroup.ru/cld და გაახარეთ თქვენი ახლობლები!",
    share_main: "კოლეგავ, გილოცავთ დღესასწაულს! შექმენით თქვენი მისალოცი ბარათი ლინკზე https://bnphrmgroup.ru/comple. გაახარეთ თქვენი ახლობლები!",
    repeat: "კიდევ ერთი ბარათის შექმნა",
    hint: "სურათის მაღალი ხარისხით შენახვისთვის გირჩევთ ელფოსტით გაზიარებას ან სურათის გადმოწერას და გაუზიარეთ ის შეუზღუდავად.",
    ready: "მზადაა",
    ready_disabled: "შექმნა...",
    back: "უკან",
    instruction: {
      title: "ინსტრუქცია",
      subtitle: "ეს გენერატორი საშუალებას გაძლევთ შექმნათ სახელობითი ბარათი.",
      p1: "შეიყვანეთ სახელი ველში",
      p2: "შეგიძლიათ შეიყვანოთ სხვა სახელი, დაჭერით ღილაკზე",
      p3: "დასასრულებლად დააჭირეთ ღილაკს",
      p4: "რამდენიმე წამში ნახავთ შედეგს, რომელიც შეგიძლიათ გაადიდოთ მასზე დაჭერით.",
      p5: "შედეგის გადმოსაწერად დააჭირეთ ღილაკს",
      p6: "ასევე შეგიძლიათ შედეგი გაუზიაროთ კოლეგებს და შეიტანოთ დღესასწაულის ხასიათი მათთვის ახლავე.",
      p7: "თუ გსურთ ბარათის გადაკეთება ან ახლის შექმნა, დააჭირეთ ლინკს",
      p_hooks: "(ხელმისაწვდომია მოწყობილობებისთვის, რომლებიც მხარს უჭერენ ამ ფუნქციას)",
      footer: "გაახარეთ საკუთარი თავი და თქვენი კოლეგები!",
      btn: "გასაგებია, დავიწყოთ!",
    },
    print: {
      title: "ბეჭდვის ინსტრუქცია",
      desktop: "PC:",
      mobile: "მობილური მოწყობილობები:",
      p1: "შეინახეთ ბარათი თქვენს მოწყობილობაზე. გახსენით სურათი თქვენთვის სასურველ რედაქტორში, ან გააგზავნეთ ბეჭდვაზე. ან აირჩიეთ &laquo;ბეჭდვა&raquo; ფაილის მენიუდან.",
      p2: "სურათის ხარისხის შესანარჩუნებლად, შექმენით ბარათი სერვისში, შემდეგ შეინახეთ ის თქვენს მოწყობილობაზე და გააგზავნეთ ბეჭდვაზე.",
      p3: "ასევე შეგიძლიათ გააზიაროთ ბარათი მესენჯერებით. შემდეგ შეინახეთ სურათი მესენჯერიდან და გააგზავნეთ ბეჭდვაზე. თუმცა ამ მეთოდით სურათის ხარისხი მცირდება.",
    },
    modal: {
      footer: "გაახარეთ საკუთარი თავი და თქვენი კოლეგები!",
      btn: "გასაგებია, დავიწყოთ!",
    },
  },
  kz: {  // Kazakh
    step1_title: "Әріптестерге комплимент жасаңыз",
    step1_new_year: "Жаңа жылға арналған ашық хат",
    step1_birthday: "Туған күнге арналған ашық хат",
    step1_mother_day: "Аналар күніне арналған ашық хат",
    step1_doctor_day: "Медицина қызметкерлер күніне арналған ашық хат",
    step1_8march: "8 наурызға арналған ашық хат",
    step1_23february: "23 ақпанға арналған ашық хат",
    step1_placeholder: "Есімді енгізіңіз", 
    steps_title: "Ашық хат генераторы",
    step2_name: "Басқа есімді таңдау",
    step3_title: "Сіздің ашық хатыңыз сәтті жасалды!",
    print_instruction: "Басып шығару нұсқаулығы",
    events: {
      title: "Әріптесіңізді шараға шақырыңыз",
      subtitle: "Ғылыми-танымдық шараға шақыру ашық хаты",
      name_placeholder: "Есімді енгізіңіз", 
      theme_placeholder: "Тақырыпты енгізіңіз",
      place_placeholder: "Орнын енгізіңіз", 
      date_placeholder: "Уақытты енгізіңіз", 
      manager_placeholder: "Менеджердің байланысын енгізіңіз",
      steps_title: "Шақыру ашық хаты генераторы",
      step2_btn: "Мәтінді өзгерту",
      step3_btn: "Есімді өзгерту",
      stes3_title: "Сіздің шақыруыңыз сәтті жасалды!",
      share: "Құрметті әріптес, сізді біздің шарамызға шақырамыз!",
    },
    download: "Жүктеп алу",
    share: "Әріптестермен бөлісу",
    share_new_year: "Жаңа жылмен шын жүректен құттықтаймыз! Бұл жыл сізге көптеген қуанышты сәттер мен жаңа мүмкіндіктер алып келсін, ал үйіңізде әрдайым жылулық пен жайлылық орнасын!\n\nӨз құттықтау күнтізбеңізді мына сілтемемен жасаңыз: https://bnphrmgroup.ru/cld. Жақындарыңызды қуантатын болыңыз!",
    share_main: "Әріптес, сізді шын жүректен мерекемен құттықтаймыз! Өз құттықтау ашық хатыңызды мына сілтемемен жасаңыз: https://bnphrmgroup.ru/comple. Жақындарыңызды қуантатын болыңыз!",
    repeat: "Тағы жасау",
    hint: "Суреттің жоғары сапасын сақтау үшін электрондық пошта арқылы бөлісу немесе суретті жүктеп алып, оны қысу жасамай кез келген ыңғайлы әдіспен жіберу ұсынылады.",
    ready: "Дайын",
    ready_disabled: "Жасалып жатыр...",
    back: "Артқа",
    instruction: {
      title: "Нұсқаулық",
      subtitle: "Бұл генератор арқылы аттары бар ашық хат жасауға болады.",
      p1: "Есімді енгізу үшін өрісті толтырыңыз",
      p2: "Басқа есімді енгізу үшін батырманы басыңыз",
      p3: "Аяқтау үшін батырманы басыңыз",
      p4: "Қысқа уақыттан кейін сіз нәтиже көресіз, оны үлкейту үшін басыңыз.",
      p5: "Нәтижені жүктеп алу үшін батырманы басыңыз",
      p6: "Сондай-ақ, әріптестеріңізбен бөлісіп, оларға мерекелік көңіл-күй сыйлау үшін батырманы басыңыз",
      p7: "Егер ашық хатты қайта жасау немесе жаңасын жасау қажет болса, сілтемені басыңыз",
      p_hooks: "(Бұл мүмкіндік қолдайтын құрылғыларға ғана қолжетімді)",
      footer: "Өзіңізді және әріптестеріңізді қуантаңыз!",
      btn: "Түсінікті, бастайық!",
    },
    print: {
      title: "Басып шығару нұсқаулығы",
      desktop: "ПК:",
      mobile: "Мобильді құрылғылар:",
      p1: "Ашық хатты құрылғыңызға сақтаңыз. Суретті ыңғайлы редакторда ашып, басып шығарыңыз. Немесе файл мәзірінен «Басып шығару» тармағын таңдаңыз.",
      p2: "Суреттің сапасын сақтау үшін ашық хатыңызды сервис арқылы жасап, құрылғыңызға сақтап, басып шығарыңыз.",
      p3: "Сондай-ақ, ашық хатты мессенджерлер арқылы бөлісуге болады. Мессенджерден суретті құрылғыңызға сақтап, басып шығарыңыз. Бұл әдіспен сурет сапасы нашарлайды.",
    },
    modal: {
      footer: "Өзіңізді және әріптестеріңізді қуантаңыз!",
      btn: "Түсінікті, бастайық!",
    },
  },
  ro: { // Romanian
    step1_title: "Fă un compliment colegilor",
    step1_new_year: "Felicitare pentru Anul Nou",
    step1_birthday: "Felicitare de ziua de naștere",
    step1_mother_day: "Felicitare de Ziua Mamei",
    step1_doctor_day: "Felicitare de Ziua Medicului",
    step1_8march: "Felicitare pentru 8 martie",
    step1_23february: "Felicitare pentru 23 februarie",
    step1_placeholder: "Introduceți numele persoanei pe care doriți să o felicitați",
    steps_title: "Generator\u00A0de felicitări",
    step2_name: "Alegeți un alt nume",
    step3_title: "Felicitarea dvs. a fost creată cu succes!",
    print_instruction: "Instrucțiuni pentru imprimare",
    events: {
      title: "Invită un coleg la un eveniment",
      subtitle: "Felicitare-invitație pentru un eveniment științifico-educațional",
      name_placeholder: "Introduceți numele și prenumele invitatului",
      theme_placeholder: "Introduceți tema evenimentului",
      place_placeholder: "Introduceți locația evenimentului",
      date_placeholder: "Introduceți data și ora evenimentului",
      manager_placeholder: "Introduceți contactele managerului",
      steps_title: "Generator\u00A0de felicitări-invitații",
      step2_btn: "Modificați textul",
      step3_btn: "Modificați numele",
      step3_title: "Invitația dvs. a fost creată cu succes!",
      share: "Dragă coleg, vă invităm la evenimentul nostru!",
    },
    download: "Descărcați",
    share: "Distribuiți cu colegii",
    share_new_year: "Vă urăm din suflet un An Nou fericit! Fie ca acesta să aducă multe momente de bucurie și noi oportunități, iar acasă să domnească mereu căldura și confortul!\n\nCreați propriul calendar de felicitări folosind linkul https://bnphrmgroup.ru/cld. Faceți o bucurie celor dragi!",
    share_main: "Dragă coleg, vă felicităm din suflet cu ocazia sărbătorii! Creați propria felicitare folosind linkul https://bnphrmgroup.ru/comple. Faceți o bucurie celor dragi!",
    repeat: "Creați altă felicitare",
    hint: "Pentru a păstra calitatea înaltă a imaginii, se recomandă distribuirea prin e-mail sau descărcarea imaginii și trimiterea acesteia fără comprimare prin orice metodă convenabilă.",
    ready: "Gata",
    ready_disabled: "Creare în curs...",
    back: "Înapoi",
    instruction: {
      title: "Instrucțiuni",
      subtitle: "Acest generator vă permite să creați o felicitare personalizată.",
      p1: "Introduceți numele în câmpul respectiv.",
      p2: "Puteți introduce un alt nume apăsând pe buton.",
      p3: "Pentru a finaliza, apăsați butonul.",
      p4: "După un timp, veți vedea rezultatul, care poate fi mărit apăsând pe el.",
      p5: "Puteți descărca rezultatul apăsând butonul.",
      p6: "De asemenea, puteți distribui rezultatul colegilor și să aduceți o atmosferă festivă chiar acum apăsând pe buton.",
      p7: "Dacă doriți să recreați felicitarea sau să creați una nouă, apăsați pe link.",
      p_hooks: "(Disponibil pentru dispozitive care acceptă această funcție)",
      footer: "Bucurați-vă pe voi și pe colegii voștri!",
      btn: "Am înțeles, să începem!",
    },
    print: {
      title: "Instrucțiuni pentru imprimare",
      desktop: "PC:",
      mobile: "Dispozitive mobile:",
      p1: "Salvați felicitarea pe dispozitivul dvs. Deschideți imaginea în editorul preferat și trimiteți-o la imprimare. Sau selectați opțiunea «Imprimare» din meniul fișierului.",
      p2: "Pentru a păstra calitatea imaginii, generați felicitarea în serviciu, apoi salvați-o pe dispozitivul dvs. și trimiteți-o la imprimare.",
      p3: "De asemenea, felicitarea poate fi distribuită prin mesagerii. Apoi salvați imaginea din mesagerie pe dispozitivul dvs. și trimiteți-o la imprimare. Cu toate acestea, calitatea imaginii va fi mai slabă. Prin această metodă, calitatea se pierde.",
    },
    modal: {
      footer: "Bucurați-vă pe voi și pe colegii voștri!",
      btn: "Am înțeles, să începem!",
    },
  },
};