<template>
    <div id="app">
      <div id='maincardstep'>
        <img src="../images/logo.png" alt="" class='logo'>
        <h1 class="main-title">{{ locale.postcard.title }}</h1>
        <div class="cardstep-wrapper">
          <a href="postcard/event" class="card">
            <img src="../images/event.jpg" alt="">
            <span>{{ locale.postcard.event }}</span>
          </a>
          <a href="postcard/new_year" class="card">
            <img src="../images/newyear.jpg" alt="">
            <span>{{ locale.postcard.new_year }}</span>
          </a>
          <a href="postcard/mother_day" class="card">
            <img src="../images/motherday.jpg" alt="">
            <span>{{ locale.postcard.mother_day }}</span>
          </a>
          <a href="postcard/birthday" class="card">
            <img src="../images/birthday.jpg" alt="">
            <span>{{ locale.postcard.birthday }}</span>
          </a>
          <a href="postcard/doctor_day" class="card">
            <img src="../images/medicday.jpg" alt="">
            <span>{{ locale.postcard.doctor_day }}</span>
          </a>
          <a href="postcard/8march" class="card">
            <img src="../images/8march.jpg" alt="">
            <span>{{ locale.postcard.march}}</span>
          </a>
          <a href="postcard/23february" class="card">
            <img src="../images/23february.jpg" alt="">
            <span>{{ locale.postcard.february}}</span>
          </a>
        </div>
        <a class="prevBtn" href="../language">
          <img src="../images/prevBtn.svg" alt="">
          {{ locale.postcard.back }}
        </a>
      </div>
    </div>
  </template>

<script>
  import { localPostCard } from '../locales/localPostCard.js';

  const getLangFromURL = () => {
    const path = window.location.pathname;
    const match = path.match(/\/compl\/(\w{2})\//);
    return match ? match[1] : 'ru';
  };

  export default {
    data() {
      const lang = getLangFromURL();
      const locale = localPostCard[lang] || localPostCard['ru'];
      return { locale };
    },
  };
</script>
  
  <style scoped lang='scss'>
  #app {
    font-family: Gilroy;
    height: 100%;
  }
  
  h1, h2 {
      margin: 0;
    }
  
    h2 {
      font-size: 50px;
      line-height: 59px;
      color: #1758A8;
  
      @media (max-width: 992px) {
        font-size: 24px;
      }
    }
  
    h1 {
      font-size: 64px;
      line-height: 78px;
  
      @media (max-width: 992px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
  
  #maincardstep {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 40px 100px;
  
    @media (max-width: 992px) {
      padding: 20px 20px 50px;
      height: unset;
    }
  
    .logo {
      width: 258px;
      // height: 159px;
      padding-bottom: 60px;
  
      @media (max-width: 992px) {
        width: 189px;
        // height: 113px;
      }
    }
  
    .main-title {
      margin-bottom: 90px;
  
      @media (max-width: 992px) {
        margin-bottom: 35px;
      }
    }
  }
  
  .prevBtn {
    margin: 70px auto 0;
    padding: 15px 35px;
    border: 0;
    background-color: #D9D9D9;
    display: flex;
    gap: 8px;
    font-size: 20px;
    line-height: 24px;
    border-radius: 100px;
    text-decoration: none;
    color: black;
  
    @media (min-width: 1439px) {
      margin: 70px 0 0 auto;
    }
  
    & img {
      transition: transform 0.3s ease-out;
    }
  
    &:hover {
      img {
        transform: translateX(-10px);
      }
    }
  }

  .cardstep-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 0 10px;
    width: 100%;
  
    @media (max-width: 1439px) {
      justify-content: center;
      gap: 70px;
      padding: 0;
    }
  
  }
  
  .card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-decoration: none;
  
    & img {
      width: 100%;
      margin: 0 auto;
      height: auto;
      min-width: 200px;
      max-width: 243px;
      transition: transform 0.3s ease-out;
  
      @media (max-width: 992px) {
        max-width: 400px;
        min-width: 300px;
      }
    }
  
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  
    span {
      font-size: 20px;
      line-height: 24px;
      color: black;
      max-width: 243px;
  
      @media (max-width: 992px) {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
  
  </style>
  
