<template> 
  <div id="app"> 
    <div id='languagestep'> 
      <img src="../images/logo.png" alt="" class='logo'> 
      <h1 class="main-title">Выбери язык открытки</h1> 
      <div class="language-wrapper-dekstop"> 
        <a @click="changeLanguage('ru')" class="language"> 
          <img src="../images/ru.png" alt="Русский язык"> 
          <span>Русский</span> 
        </a> 
        <a @click="changeLanguage('am')" class="language"> 
          <img src="../images/am.png" alt="Армянский язык"> 
          <span>Армянский</span> 
        </a> 
        <a @click="changeLanguage('uz')" class="language"> 
          <img src="../images/uz.png" alt="Узбекский язык"> 
          <span>Узбекский</span> 
        </a> 
        <a @click="changeLanguage('kz')" class="language"> 
          <img src="../images/kz.png" alt="Казахский язык"> 
          <span>Казахский</span> 
        </a> 
        <a @click="changeLanguage('ge')" class="language"> 
          <img src="../images/ge.png" alt="Грузинский язык"> 
          <span>Грузинский</span> 
        </a> 
        <a @click="changeLanguage('md')" class="language"> 
          <img src="../images/md.png" alt="Молдавский язык"> 
          <span>Молдавский</span> 
        </a> 
        <a @click="changeLanguage('az')" class="language"> 
          <img src="../images/az.png" alt="Азербайджанский язык"> 
          <span>Азербайджанский</span> 
        </a> 
        <a @click="changeLanguage('ro')" class="language">
          <img src="../images/ro.png" alt="Румынский язык"> 
          <span>Румынский</span> 
        </a> 
      </div> 
      <div class="language-wrapper-mobile">
        <div class="select select-country" @click="toggleDropdown">
          <div
            class="select__top"
            :class="{ selected: selectedLanguage !== 'Страна/Country*' }"
          >
            <div class="select__label">Страна/Country*</div>
            <div class="select__text">{{ selectedLanguage }}</div>
          </div>
          <div
            ref="languageDropdown"
            class="select__dropdown"
            :class="{ select_hide: !isDropdownVisible }"
          >
            <div
              v-for="item in [
                { name: 'Россия', code: 'ru' },
                { name: 'Азербайджан', code: 'az' },
                { name: 'Армения', code: 'am' },
                { name: 'Грузия', code: 'ge' },
                { name: 'Казахстан', code: 'kz' },
                { name: 'Молдавия', code: 'md' },
                { name: 'Узбекистан', code: 'uz' },
              ]"
              :key="item.code"
              class="select__item"
              @click="selectLanguage(item.name, item.code)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <a class='btn' @click="changeLanguage(selectedCode)" :disabled="!selectedLanguage">Готово</a>
      </div>
      <a class="prevBtn" href="../compl"> 
        <img src="../images/prevBtn.svg" alt=""> 
        Назад 
      </a> 
    </div> 
  </div> 
</template> 
 
<script> 
export default {
  data() {
    return {
      selectedLanguage: "",
      selectedCode: "",
      isDropdownVisible: false,
    };
  },
  methods: {
    changeLanguage(lang) {
      window.location.href = `/compl/${lang}/postcard`; 
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    selectLanguage(language, code) {
      this.selectedLanguage = language;
      this.selectedCode = code;
      this.isDropdownVisible = true;
      console.log(this.isDropdownVisible)
    },
    handleOutsideClick(event) {
      const dropdown = document.querySelector('.select');
      if (!dropdown) return;
      if (!dropdown.contains(event.target)) {
        this.isDropdownVisible = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
};
</script>

<style scoped lang='scss'>
#app {
  font-family: Gilroy;
  height: 100%;
}

h1, h2 {
    margin: 0;
  }

  h2 {
    font-size: 50px;
    line-height: 59px;
    color: #1758A8;

    @media (max-width: 992px) {
      font-size: 24px;
    }
  }

  h1 {
    font-size: 64px;
    line-height: 78px;

    @media (max-width: 992px) {
      font-size: 30px;
      line-height: 38px;
    }
  }

#languagestep {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 40px 100px;

  @media (max-width: 992px) {
    padding: 20px 20px 50px;
    height: unset;
  }

  .logo {
    width: 258px;
    // height: 159px;
    padding-bottom: 60px;

    @media (max-width: 992px) {
      width: 189px;
      // height: 113px;
    }
  }

  .main-title {
    margin-bottom: 90px;

    @media (max-width: 992px) {
      margin-bottom: 35px;
    }
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 20px;
  box-sizing: border-box;
  border: none;
  padding: 10px;
  background: #4A7EBD;
  cursor: pointer;
  color: white;
  text-align: center;
  border-radius: 100px;
  width: 250px;
  height: 54px;
  transition: color 0.3s ease-out, background-color 0.3s ease-out, box-shadow 0.3s ease-out;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not([disabled]),
  &:focus-visible:not([disabled]) {
    color: #4A7EBD;
    background-color: white;
    box-shadow: inset 0 0 0 1px #4A7EBD;
  }
}

.prevBtn {
  margin: 70px auto 0;
  padding: 15px 35px;
  border: 0;
  background-color: #D9D9D9;
  display: flex;
  gap: 8px;
  font-size: 20px;
  line-height: 24px;
  border-radius: 100px;
  text-decoration: none;
  color: black;

  @media (min-width: 1439px) {
    margin: 70px 0 0 auto;
  }

  & img {
    transition: transform 0.3s ease-out;
  }

  &:hover {
    img {
      transform: translateX(-10px);
    }
  }
}

.language-wrapper-dekstop {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  padding: 0 10px;
  width: 100%;

  @media (max-width: 1439px) {
    justify-content: center;
    gap: 70px;
    padding: 0;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.language-wrapper-mobile {
  display: none;
  width: 100%;
  max-width: 500px;

  @media (max-width: 767px) {
    display: block;
  }
}

.language {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-decoration: none;

  & img {
    width: 100%;
    margin: 0 auto;
    height: auto;
    min-width: 200px;
    transition: transform 0.3s ease-out;

    @media (max-width: 992px) {
      max-width: 400px;
      min-width: 300px;
    }
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  span {
    font-size: 20px;
    line-height: 24px;
    color: black;

    @media (max-width: 992px) {
      font-size: 24px;
      line-height: 29px;
    }
  }
}

.select {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
}

.select__top {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 24px;
  font-family: "Futura PT", sans-serif;
  font-size: 20px;
  line-height: 28px;
  border: 1px solid #dce2e8;
  cursor: pointer;
  transition: 0.3s linear;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    width: 28px;
    height: 28px;
    background: url('../images/select-arrow.svg') no-repeat center;
    background-size: auto;
    background-size: contain;
    transition: 0.3s linear;
  }

  &.selected .select__label {
    font-size: 14px;
    line-height: 1;
    top: 18px;
  }

  &.selected .select__text {
    height: auto;
  }
}

.select__label {
  padding-right: 25px;
  color: #637586;
  transition: 0.3s linear;
}

.select__text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding-right: 25px;
  height: 0;
  display: inline-block;
}

.select__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid #dce2e8;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgb(220, 226, 232);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(220, 226, 232);
  border-top: none;
  border-bottom: none;
  transform-origin: 50% 0;
  transition: 0.3s linear;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.15);
  z-index: 10;
  max-height: 280px;
  overflow: auto;

  &.select_hide {
    transform: scaleY(0);
  }
}

.select__item {
  padding: 20px 24px;
  border-bottom: 1px solid #dce2e8;
  cursor: pointer;
  transition: 0.3s linear;

  &.item-selected {
    background: #a0c6f5;
    color: #ffffff;
  }
}

</style>

