<template>
  <div id="app">
    <div id='step1' v-if='currentStep == 1'>
      <img src="../images/logo.png" alt="" class='logo'>
      <h1 class="main-title">{{ locale.events.title }}</h1>
      <h2>{{ locale.events.subtitle }}</h2>
      <div class="form-block">
        <div class="input-wrapper">
          <input type="text" v-model="nameEvent" :placeholder=locale.events.name_placeholder class="form-area" maxlength="70" @input="checkInput">
          <span class="input-line"></span>
        </div>
        <div class="input-wrapper">
          <input type="text" v-model="themeEvent" :placeholder=locale.events.theme_placeholder class="form-area" maxlength="110"  @input="checkInput">
          <span class="input-line"></span>
        </div>
        <div class="input-wrapper">
          <input type="text" v-model="placeEvent" :placeholder=locale.events.place_placeholder class="form-area" maxlength="80"  @input="checkInput">
          <span class="input-line"></span>
        </div>
        <div class="input-wrapper">
          <input type="text" v-model="dataEvent" :placeholder=locale.events.date_placeholder class="form-area" maxlength="40" @input="checkInput">
          <span class="input-line"></span>
        </div>
        <div class="input-wrapper">
          <input type="text" v-model="managerEvent" :placeholder=locale.events.manager_placeholder class="form-area" maxlength="50" @input="checkInput">
          <span class="input-line"></span>
        </div>
      </div>
      <div class='button-wrapper'>
          <button class='btn' @click.prevent='NextStep' :disabled="!isInputValid">{{ locale.ready }}</button>
        </div>
      <a class="prevBtn" href="../postcard">
        <img src="../images/prevBtn.svg" alt="">
        {{ locale.back }}
      </a>
    </div>
    <div id='step2' v-if='currentStep == 2'>
      <div class='header'>
        <div>
          <img src="../images/logo.png" alt="" class='logo' @click='repeat'>
        </div>
        <a @click.prevent='showInstruction'>
          <img src='../images/help.svg' class='help' @click='showInstruction'>
        </a>
      </div>
      <div>
        <h1 class="main-title">{{ locale.events.steps_title }}</h1>
      </div>
      <div class='template content' ref="content">
        <img :src="templates[lang]" alt="">
        <div class="name-before">{{ formattedNameEvent }}</div>
        <div class="name-fixed">{{ nameEvent }}</div>
        <div :class="['decription-fixed theme', lang]">{{ themeEvent }}</div>
        <div :class="['decription-fixed place', lang]">{{ placeEvent }}</div>
        <div :class="['decription-fixed data', lang]">{{ dataEvent }}</div>
        <div :class="['decription-fixed manager', lang]">{{ managerEvent }}</div>
      </div>
      <template v-if='!isGenerating'>
        <div class='buttons'>
          <button class='btn' @click.prevent='generateImage' v-if='!isGenerating'>{{ locale.ready }}</button>
          <button class='btn' disabled v-else>{{ locale.ready_disabled }}</button>

          <button class='btn' @click.prevent='anotherName'>
            {{ locale.events.step2_btn }}
          </button>
        </div>
      </template>

      <div class='preloader preloader--gradient' v-else>
        <div><span></span></div>
        <div><span></span></div>
        <div><span></span></div>
        
        <div><span></span></div>
        <div><span></span></div>
        <div><span></span></div>
        
        <div><span></span></div>
        <div><span></span></div>
        <div><span></span></div>
      </div>
      <button type="button" class="prevBtn" @click.prevent='prevStep'>
        <img src="../images/prevBtn.svg" alt="">
        {{ locale.back }}
      </button>
    </div>
    <div id='step3' v-if='currentStep == 3'>
      <div class='header'>
        <div>
          <img src="../images/logo.png" alt="" class='logo' @click='repeat'>
        </div>
        <a @click.prevent='showInstruction'>
          <img src='../images/help.svg' class='help' @click='showInstruction'>
        </a>
      </div>
      <div>
        <h1 class="main-title">{{ locale.events.steps_title }}</h1>
      </div>
      <div class='content'>
        <h3>{{ locale.events.step3_title }}</h3>
        <viewer :images='[generatedImage]' :options='viewerOptions'>
          <img :src="generatedImage" class='result'>
        </viewer>
        <div class='buttons'>
          <a @click.prevent='showPrintInstruction' class="print-instruction-btn">
            {{ locale.print_instruction }} <img src='../images/help.svg' class='help' @click='showPrintInstruction'>
          </a>
          <a class='btn' @click.prevent='download'>{{ locale.download }}</a>
          <button class='btn' v-if="webShareApiSupported" @click.prevent='shareResult'>{{locale.share }}<sup>*</sup></button>
          <a href='#' @click.prevent='repeatName' class='btn orange'> {{ locale.events.step3_btn }}</a>
          <a href='#' @click.prevent='repeat' class='repeat'>{{ locale.repeat }}</a>
        </div>

        <div class='hint' v-if="webShareApiSupported">
          <sup>*</sup> {{ locale.hint }}
        </div>
      </div>
    </div>
    <modal name="instruction" :adaptive='true' :scrollable='true' :height="'auto'">
      <a class='modal-close' @click.prevent='hideInstruction'>&times;</a>
      <div class='modal-content'>
        <center><span class="modal-title">{{ locale.instruction.title }}</span></center>
        <p>{{ locale.instruction.subtitle }}</p>
        <ol>
          <li>{{ locale.instruction.p1 }} <b>&laquo;{{ locale.step1_placeholder }}&raquo;</b>.</li>
          <li>{{ locale.instruction.p2 }} <b>&laquo;{{ locale.step2_name }}&raquo;</b>.</li>
          <li>{{ locale.instruction.p3 }} <b>&laquo;{{ locale.ready }}&raquo;</b>.</li>
          <li>{{ locale.instruction.p4 }}</li>
          <li>{{ locale.instruction.p5 }} <b>&laquo;{{ locale.download }}&raquo;</b>.</li>
          <li>{{ locale.instruction.p6 }} <b>&laquo;{{ locale.share }}&raquo;</b> <i>{{ locale.instruction.p_hooks }}</i>.</li>
          <li>{{ locale.instruction.p7 }} <b>&laquo;{{ locale.repeat }}&raquo;</b></li>
        </ol>
        <center>
          <b>
            <p>{{ locale.modal.footer }}</p>
          </b>
        </center>
        <a href='#' @click.prevent='hideInstruction'>{{ locale.modal.btn }}</a>
      </div>
    </modal>

    <modal name="printInstruction" :adaptive='true' :scrollable='true' :height="'auto'">
      <a class='modal-close' @click.prevent='hidePrintInstruction'>&times;</a>
      <div class='modal-content'>
        <center><span class="modal-title">{{ locale.print.title }}</span></center>
        <p><strong><u>{{ locale.print.desktop }}</u></strong> {{ locale.print.p1 }}</p>
        <p><strong><u>{{ locale.print.mobile }}</u></strong> {{ locale.print.p2 }}</p>
        <p>{{ locale.print.p3 }}</p>
        <center>
          <b>
            <p>{{ locale.modal.footer }}</p>
          </b>
        </center>
        <a href='#' @click.prevent='hidePrintInstruction'>{{ locale.modal.btn }}</a>
      </div>
    </modal>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import downloadjs from 'downloadjs';
import 'normalize.css';
import 'viewerjs/dist/viewer.css';
import 'vue-js-modal/dist/styles.css';
import { localCards } from '../locales/localCards.js';
import { getLangFromURL } from '../utils/utils.js';

export default {
  data: function () {
    const lang = getLangFromURL();
    const locale = localCards[lang] || localCards['ru'];

    return {
      lang,
      locale,
      templates: {
        ru: require('../images/event/event-template-ru.png'),
        am: require('../images/event/event-template-am.png'),
        kz: require('../images/event/event-template-kz.png'),
        uz: require('../images/event/event-template-uz.png'),
        ge: require('../images/event/event-template-ge.png'),
        md: require('../images/event/event-template-md.png'),
        az: require('../images/event/event-template-az.png'),
        ro: require('../images/event/event-template-ro.png'),
      },
      currentStep: 1,
      nameEvent:'',
      themeEvent:'',
      placeEvent:'',
      dataEvent:'',
      managerEvent:'',
      isInputValid: false,
      generatedImage: null,
      isGenerating: false,
      viewerOptions: { "inline": false, "button": true, "navbar": false, "title": false, "toolbar": false, "tooltip": false, "movable": false, "zoomable": true, "rotatable": false, "scalable": false, "transition": false, "fullscreen": false, "keyboard": false }
    }
  },
  methods: {
    anotherName: function(_event) {
      this.currentStep = 1;
      this.nameEvent = '';
      this.themeEvent = '';
      this.placeEvent = '';
      this.dataEvent = '';
      this.managerEvent = '';
      this.isInputValid = false;
      if (this.$metrika) this.$metrika.reachGoal('ANOTHER_NAME_23');
    },
    prevStep: function(_event) {
      this.currentStep -= 1;
    },
    checkInput: function(_event) {
      this.isInputValid =
        this.nameEvent.trim().length > 0 &&
        this.themeEvent.trim().length > 0 &&
        this.placeEvent.trim().length > 0 &&
        this.dataEvent.trim().length > 0 &&
        this.managerEvent.trim().length > 0;
    },
    NextStep: function(_event) {
      if (this.isInputValid) {
        this.currentStep = 2;
      }
      if (this.$metrika) this.$metrika.reachGoal('NEXT_STEP_23');
    },
    async generateImage() {
      this.isGenerating = true;

      const element = this.$refs.content;
      element.classList.add('gap');

      try {
        const canvas = await html2canvas(element, {
          scale: 4,
        });
        const dataURL = canvas.toDataURL('image/png');
      
        this.generatedImage = dataURL;
      } finally {
        this.currentStep = 3;
        this.isGenerating = false;
      }
      if (this.$metrika) this.$metrika.reachGoal('GENERATE_IMAGE_23');
    },
    shareResult: async function() {
      const response = await fetch(this.generatedImage);
      const blob = await response.blob();
      const file = new File([blob], 'event.png', { type: blob.type });
      await navigator.share({
        title: this.locale.events.share,
        files: [file],
      });
      if (this.$metrika) this.$metrika.reachGoal('SHARE_RESULT_23');
    },
    repeat: function() {
      this.currentStep = 1;
      this.nameEvent = '';
      this.themeEvent = '';
      this.placeEvent = '';
      this.dataEvent = '';
      this.managerEvent = '';
      this.isInputValid = false;
      this.generatedImage = null;
      if (this.$metrika) this.$metrika.reachGoal('REPEAT_23');
    },
    repeatName: function() {
      this.currentStep = 1;
      this.nameEvent = '';
      this.isInputValid = false;
      this.generatedImage = null;
      if (this.$metrika) this.$metrika.reachGoal('REPEAT_23');
    },
    download: function() {
      if (this.$metrika) this.$metrika.reachGoal('DOWNLOAD_23');
      downloadjs(this.generatedImage, 'event.png', 'image/png');
    },
    showInstruction() {
      this.$modal.show('instruction');
    },
    hideInstruction() {
      this.$modal.hide('instruction')
    },

    showPrintInstruction() {
      this.$modal.show('printInstruction');
    },
    hidePrintInstruction() {
      this.$modal.hide('printInstruction')
    },
  }, 
  computed: {
    webShareApiSupported: function() {
      return navigator.canShare
    },
    formattedNameEvent() {
      if (!this.nameEvent) return '';

      const lang = getLangFromURL();
      const lastChar = this.nameEvent.trim().slice(-1).toLowerCase();

      if (lang === 'ru') {
        if (['а', 'я'].includes(lastChar)) {
          return 'Уважаемая,';
        } else {
          return 'Уважаемый,';
        }
      } else {
        return '';
      }
    }
  }
}
</script>

<style scoped lang='scss'>
#app {
  font-family: Gilroy;
  height: 100%;
}

h1, h2 {
    margin: 0;
  }

  h2 {
    font-size: 50px;
    line-height: 59px;
    color: #1758A8;

    @media (max-width: 992px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  h1 {
    font-size: 64px;
    line-height: 78px;

    @media (max-width: 992px) {
      font-size: 30px;
      line-height: 38px;
    }
  }

#step1 {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 50px 50px 50px;

  height: 100%;

  @media (max-width: 992px) {
    padding: 20px 20px 50px;
    height: unset;
  }

  .logo {
    width: 258px;
    // height: 159px;
    padding-bottom: 60px;

    @media (max-width: 992px) {
      width: 189px;
      // height: 113px;
    }
  }

  .main-title {
    margin-bottom: 50px;

    @media (max-width: 992px) {
      margin-bottom: 25px;
    }
  }

  .uploadBtn {
    margin-top: 69px;

    width: 311px;
    height: 78.5px;

    background: #FFFFFF;
    border: 2px solid #4A7EBD;
    box-sizing: border-box;
    border-radius: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;

    text-align: center;

    color: #4A7EBD;

    cursor: pointer;

    transition: color 0.5s ease-out, background-color 0.5s ease-out;
    
    svg {
      margin-right: 20px;
      transition: color 0.5s ease-out;
    }

    &:hover,
    &:focus-visible {
      background-color: #4A7EBD;
      color: white;
    }

    @media (max-width: 992px) {
      margin-top: 30px;

      width: 228px;
      height: 72px;

      font-size: 20px;
      line-height: 26px;
    }
  }

  .form-block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    width: 100%;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  .input-wrapper {
    width: 100%;
    position: relative;
    max-width: 550px;

    &:last-child {
      grid-column: 1/-1;
    }

    @media (max-width: 992px) {
      max-width: 400px;
    }

    @media (max-width: 767px) {
      max-width: 320px;
    }
  }

  .form-area {
    margin-top: 60px;
    font-size: 28px;
    line-height: 58px;
    border: 0;
    width: 100%;
    height: 65px;
    border-bottom: 1px solid #DCDCDC;
    outline: none;
    resize: none;
    transition: border 0.5s ease-in-out;

    @media (max-width: 992px) {
      font-size: 20px;
    }

    @media (max-width: 767px) {
      font-size: 16px;
      margin-top: 30px;
    }

    &::placeholder {
      font-size: 28px;
      line-height: 58px;
      color: rgb(30, 30, 30);
      opacity: 0.8;

      @media (max-width: 992px) {
      font-size: 20px;
      }

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    & ~ .input-line {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 1px;
      background-color: #1758A8;
      transition: 0.4s;
    }

    &:focus-visible ~ .input-line {
      width: 100%;
      transition: 0.4s;
      left: 0;
    }
  }


  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    .btn {
      font-size: 20px;
      box-sizing: border-box;
      border: none;
      padding: 10px;
      background: #4A7EBD;
      cursor: pointer;
      color: white;
      margin-right: 10px;
      text-align: center;
      border-radius: 100px;
      width: 300px;
      height: 54px;
      transition: color 0.3s ease-out, background-color 0.3s ease-out, box-shadow 0.3s ease-out;
      &:last-child {
        margin-right: 0;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:hover:not([disabled]),
      &:focus-visible:not([disabled]) {
        color: #4A7EBD;
        background-color: white;
        box-shadow: inset 0 0 0 1px #4A7EBD;
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;
      margin-top: 30px;
      align-items: center;
      width: 100%;
      .btn {
        margin: 0 0 10px 0;
        padding: 10px;
        width: 200px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.prevBtn {
  position: fixed;
  right: 5%;
  bottom: 5%;
  padding: 15px 35px;
  margin: 0;
  border: 0;
  background-color: #D9D9D9;
  display: flex;
  gap: 8px;
  font-size: 20px;
  line-height: 24px;
  border-radius: 100px;
  text-decoration: none;
  color: black;

  @media (max-width: 992px) {
      position: unset;
      right: unset;
      bottom: unset;
      margin: 70px auto 0;
    }

  & img {
    transition: transform 0.3s ease-out;
  }

  &:hover {
    img {
      transform: translateX(-10px);
    }
  }
}

#step2, #step3 {
  padding: 30px;

  @media (max-width: 992px) {
    padding: 20px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 40px;

    @media (max-width: 992px) {
      margin-bottom: 30px;
    }

    .logo {
      height: 65px;
      cursor: pointer;
    }

    .help {
      cursor: pointer;
    }
    
    @media (max-width: 992px) {
      .help {
        margin-right: 15px;
      }
    }
  }

  .main-title {
    margin: -60px 0 20px;
    text-align: center;

    @media (max-width: 992px) {
      margin: 20px 0 20px;
    } 
  }
}

#step2 {

  .range {
    max-width: 960px;
    width: 67%;
    margin: 0 auto;
  }
  
  .template {
    position: relative;
    width: 353px;
    margin: 0 auto 40px;
    font-size: 14px;
    line-height: 24px;

    &.gap {
      .name-before {
        bottom: 55.9%;
      }

      .name-fixed {
        bottom: 52.2%
      }

      .decription-fixed {
        &.theme {
          &.ru {
            top: 56.3%;
          }

          &.am {
            top: 56.6%;
          }

          &.uz {
            top: 56.3%;
          }

          &.kz {
            top: 56.4%;
          }

          &.ge {
            top: 56.4%;
          }

          &.md {
            top: 56.2%;
          }

          &.az {
            top: 56.4%;
          }

          &.ro {
            top: 56.2%;
          }
        }

        &.place {
          &.ru {
            top: 66%;
          }

          &.am {
            top: 66.3%;
          }

          &.uz {
            top: 66%;
          }

          &.kz {
            top: 66.1%;
          }

          &.ge {
            top: 66.1%;
          }

          &.md {
            top: 65.9%;
          }

          &.az {
            top: 66.1%;
          }

          &.ro {
            top: 65.9%;
          }
        }

        &.data {
          &.ru {
            top: 72.5%;
          }

          &.am {
            top: 72.8%;
          }

          &.uz {
            top: 72.6%;
          }

          &.kz {
            top: 72.6%;
          }

          &.ge {
            top: 72.7%;
          }

          &.md {
            top: 72.5%;
          }

          &.az {
            top: 72.6%;
          }

          &.ro {
            top: 72.5%;
          }
        }

        &.manager {
          &.ru {
            top: 78.8%;
          }

          &.am {
            top: 79.1%;
          }

          &.uz {
            top: 78.8%;
          }

          &.kz {
            top: 78.9%;
          }

          &.ge {
            top: 78.9%;
          }

          &.md {
            top: 78.8%;
          }

          &.az {
            top: 79%;
          }

          &.ro {
            top: 78.8%;
          }
        }
      }
    }

    img {
      position: relative;
      max-width: 100%;
      min-height: 660px;
    }

    .name-before {
      position: absolute;
      color: #000;
      bottom: 55%;
      left: 20px;
      font-size: 16px;
    }

    .name-fixed {
      position: absolute;
      font-weight: 600;

      bottom: 51.5%;
      left: 20px;
      width: 285px;
      color: #000;

      @media (max-width: 992px) {
        width: 268px;
      }
    }

    .decription-fixed {
      position: absolute;
      line-height: 20px;
      color: #435D70;
      left: 20px;
      width: 310px;

      &.theme {
        &.ru {
          text-indent: 40px;
          top: 57.3%;
        }

        &.am {
          text-indent: 50px;
          top: 57.5%;
        }

        &.uz {
          text-indent: 52px;
          top: 57.2%;
        }

        &.kz {
          text-indent: 73px;
          top: 57.3%;
        }

        &.ge {
          text-indent: 44px;
          top: 57.3%;
        }

        &.md {
          text-indent: 46px;
          top: 57.1%;
        }

        &.az {
          text-indent: 54px;
          top: 57.3%;
        }

        &.ro {
          text-indent: 46px;
          top: 57.1%;
        }
      }

      &.place {
        &.ru {
          text-indent: 50px;
          top: 66.8%;
        }

        &.am {
          text-indent: 45px;
          top: 67.2%;
        }

        &.uz {
          text-indent: 40px;
          top: 66.8%;
        }

        &.kz {
          text-indent: 97px;
          top: 66.9%;
        }

        &.ge {
          text-indent: 68px;
          top: 66.9%;
        }

        &.md {
          text-indent: 44px;
          top: 66.7%;
        }

        &.az {
          text-indent: 32px;
          top: 66.9%;
        }

        &.ro {
          text-indent: 44px;
          top: 66.7%;
        }
      }

      &.data {
        &.ru {
          text-indent: 98px;
          top: 73.2%;
        }

        &.am {
          text-indent: 165px;
          top: 73.6%;
        }

        &.uz {
          text-indent: 125px;
          top: 73.3%;
        }

        &.kz {
          text-indent: 127px;
          top: 73.4%;
        }

        &.ge {
          text-indent: 129px;
          top: 73.4%;
        }

        &.md {
          text-indent: 82px;
          top: 73.2%;
        }

        &.az {
          text-indent: 94px;
          top: 73.4%;
        }

        &.ro {
          text-indent: 82px;
          top: 73.2%;
        }
      }

      &.manager {
        &.ru {
          text-indent: 110px;
          top: 79.8%;
        }

        &.am {
          text-indent: 70px;
          top: 80.1%;
        }

        &.uz {
          text-indent: 75px;
          top: 79.8%;
        }

        &.kz {
          text-indent: 84px;
          top: 79.8%;
        }

        &.ge {
          text-indent: 80px;
          top: 79.7%;
        }

        &.md {
          text-indent: 70px;
          top: 79.6%;
        }

        &.az {
          text-indent: 68px;
          top: 79.8%;
        }

        &.ro {
          text-indent: 70px;
          top: 79.6%;
        }
      }

      @media (max-width: 992px) {
        width: 292px;
      }
    }

    @media (max-width: 992px) {
      width: 335px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    .btn {
      box-sizing: border-box;
      border: none;
      padding: 10px;
      background: #4A7EBD;
      cursor: pointer;
      color: white;
      margin-right: 10px;
      text-align: center;
      border-radius: 100px;
      width: 200px;
      transition: color 0.3s ease-out, background-color 0.3s ease-out, box-shadow 0.3s ease-out;
      &:last-child {
        margin-right: 0;
      }
      &:disabled {
        opacity: 0.5;
      }

      &:hover,
      &:focus-visible {
        color: #4A7EBD;
        background-color: white;
        box-shadow: inset 0 0 0 1px #4A7EBD;
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;
      margin-top: 20px;
      align-items: center;
      width: 100%;
      .btn {
        margin: 0 0 10px 0;
        padding: 10px;
        width: 60%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

#step3 {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h3 {
    font-size: 36px;
    line-height: 46px;
    margin-top: 0;
    @media (max-width: 992px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  h4 {
    font-size: 36px;
    line-height: 46px;
    margin: 0 0 10px 0;
    text-align: center;
    @media (max-width: 992px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  img.result {
    margin-top: 50px;
    max-width: 250px;
    width: 100%;
    cursor: pointer;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    .btn {
      box-sizing: border-box;
      border: none;
      padding: 10px 10px;
      background-color: #4A7EBD;
      cursor: pointer;
      color: white;
      margin-bottom: 20px;
      text-align: center;
      text-decoration: none;
      border-radius: 100px;
      transition: color 0.3s ease-out, background-color 0.3s ease-out, box-shadow 0.3s ease-out;
      &.orange {
        background-color: #E85412;

        &:hover,
        &:focus-visible {
          color: #E85412;
          background-color: white;
          box-shadow: inset 0 0 0 1px #E85412;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }

      &:hover,
      &:focus-visible {
        color: #4A7EBD;
        background-color: white;
        box-shadow: inset 0 0 0 1px #4A7EBD;
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;
      margin-top: 20px;
      align-items: center;
      width: 100%;
      .btn {
        margin: 0 0 10px 0;
        padding: 10px;
        width: 60%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .repeat {
    position: relative;
    color: rgba(74, 126, 189, 1);
    text-align: center;
    text-decoration: none;
    max-width: 120px;
    margin: 0 auto;

    &:before {
      content: "";
      position: absolute;
      background-color: rgba(74, 126, 189, 1);
      left: 0;
      bottom: -3px;
      width: 0;
      height: 1px;
      transition: width 0.3s ease-in-out;
    }

    &:hover:before {
      width: 100%;
    }
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  max-height: calc(100% - 40px);
  overflow-y: auto;
  text-align: justify;
  ol {
    padding-left: 20px;
  }
  a {
    display: block;
    text-align: center;
    color: rgba(74, 126, 189, 1);
  }
}

.hint {
  max-width: 450px;
  width: 90%;
  text-align: justify;
  opacity: 0.5;
  padding-top: 15px;
  font-size: 75%;
  &:before {
    content: '';
    display: block;
    width: 50px;
    height: 1px;
    background: black;
    margin-bottom: 5px;
  }
}

.modal-title {
  font-size: 42px;
  line-height: 68px;
  font-weight: 700;
}

.modal-close {
  cursor: pointer;
  position: absolute;
  font-size: 26px;
  line-height: 26px;
  width: 26px;
  padding: 5px;
  right: 10px;
  top: 10px;
  text-align: center;
  background: white;
  border-radius: 5px;
  border: 1px solid black;
}

$preloaderMainColor: #0053a6;
$preloaderSecondColor: #E85412;
$preloaderWidth: 55px;
$preloaderHeight: $preloaderWidth;
/*$preloaderAnimationDuration: 1.3s;
$preloaderAnimationDelay: 30; //ms*/
/* baidin */
$preloaderAnimationDuration: 1.7s;
$preloaderAnimationDelay: 150; //ms
/* series */
/*$preloaderAnimationDuration: .9s;
$preloaderAnimationDelay: 100; //ms*/

.preloader {
  width: $preloaderWidth;
  height: $preloaderHeight;
  margin: 20px auto;
  overflow: hidden;
  
  div {
    padding: 0;
    margin: 0;
    display: block;
    float: left;
    width: 27%;
    height: 27%;
    margin-right: 9%;
    margin-bottom: 9%;
    background-color: $preloaderMainColor;
    
    &:nth-child(3n){
      margin-right: 0;
    }
    
    span {
      display: block;
      height: 100%;
      width: 100%;
      opacity: 0;
      background-color: $preloaderSecondColor;
      content: '';
      animation-duration: $preloaderAnimationDuration;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
    }
    
    @for $i from 1 through 9 {
      &:nth-child(#{$i}) {
        span{
          animation-delay: #{$i * $preloaderAnimationDelay}ms;
        }
      }
    }
  }
}

.preloader--gradient {
  // margin-left: calc($preloaderWidth / 2);
  div {
    span {
      animation-name: gradient;
    }
  }
}

.print-instruction-btn {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
  align-items: center;
  cursor: pointer;
}

@keyframes gradient {
    0% {opacity: 0;}
    50% {opacity: 1;}
    100% {opacity: 0;}
}
</style>
